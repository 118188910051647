<template>
<div class="screenHeight">
    <div class="text-center">
        <img src="../assets/BRTopimage-01.png" alt="Balkan roads top image" class="topPictureMobile" />
    </div>
    <section class="login">
        <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet" />
        <div class="container">
            <h1 class="headlinePhone pb-5 mb-2">Sign in</h1>
            <h1 class="headlineTablet pb-5 mb-2">Welcome Balkaneros</h1>
            <!-- Ovdje ide alert za wrong username ili password -->
            <form @submit.prevent="loginUser">
                <div class="form-group pb-3">
                    <input type="email" class="form-control" name="mail" placeholder="Email" v-model="email" required>
                </div>

                <div class="form-group pb-3 d-flex position-relative">
                    <input :type="visibility" class="form-control" placeholder="Password" v-model="password" name="current-password" autocomplete="on" required>
                    <a href="#" class="eyeIcon" @click="showPassword">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23" height="15" viewBox="0 0 23 15">
                            <defs>
                                <clipPath id="clip-See_password">
                                    <rect width="23" height="15"/>
                                </clipPath>
                            </defs>
                            <g id="See_password" data-name="See password" clip-path="url(#clip-See_password)">
                                <g id="Group_904" data-name="Group 904" transform="translate(-562.089 12.853)">
                                    <path id="Path_1287" data-name="Path 1287" d="M578.759-11.853h-9.541l-6.129,6.468L569.218.906h9.541l6.081-6.291Zm-2.485,9.277h-4.59l-2.447-2.857,2.447-2.943h4.59l2.418,2.943Z" fill="#707070"/>
                                    <path id="Subtraction_6" data-name="Subtraction 6" d="M571.677-5.45l1.184-1.706h2.221l1.17,1.706-1.17,1.659h-2.221Z" fill="#707070"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>

                <!--<div class="form-check pb-4 text-left">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="rememberMe">
                    <label class="form-check-label ml-2 pl-1" for="rememberMe">Remember me</label>
                </div>-->

                <div class="form-group text-center">
                    <button type="submit" class="btn btn-secondary w-100 text-uppercase">Log in</button>
                </div>
            </form>
            <div class="spacingDiv text-center">Forgot password? <router-link :to="{name: 'ForgotPassword'}" class="signinLink">Recover here.</router-link></div>
        </div>
        <div class="positionBottom text-center pb-2">
            Don't have an account? <router-link :to="{name: 'Register'}" class="signinLink">Register here.</router-link>
        </div>
    </section>
</div>
</template>

<script>
import axios from 'axios';
import { LOGIN_URL } from '../config/variables';
import {useToast} from "vue-toastification";
export default {
    setup() {
        const toast = useToast();
        return {toast}
    },
    data() {
        return {
            email: "",
            password: "",
            visibility: "password",
        }
    },
    methods: {
        loginUser() {
            axios.post(LOGIN_URL, {
                    email: this.email,
                    password: this.password
                })
                .then((response) => {
                    this.$store.dispatch('login', response.data.access_token)
                    this.$router.push({
                        name: 'Home',
                    })
                })
                .catch((error) =>{
                    console.log(error);
                    this.toast.error('Wrong email or password');
                });
        },
        showPassword(){
            if(this.visibility === 'text'){
                this.visibility = 'password'

            }else{
                this.visibility = 'text';
            }
        },
    },
}
</script>

<style lang="scss">
.eyeIcon{
    position: absolute;
    right: 16px;
    margin-top: 16px;
    text-decoration: none;

    @media only screen and (min-width: 576px){

    }
}
</style>
